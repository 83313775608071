import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import Prefooter from '../components/prefooter'

export const SuccessPageTemplate =({
    title,content
}) => (
    <div className="success-content content">
        <h1>{title}</h1>
        <div className="page-content" dangerouslySetInnerHTML={{__html: content}} />
    </div>
)

SuccessPageTemplate.propTypes = {
    title: PropTypes.string,
}

const SuccessPage = ({ data }) => {
    const { markdownRemark: post } = data
  
    return (
      <Layout language="English" englishAudio="none" irishAudio="none">
        <Helmet title={post.frontmatter.title}  bodyAttributes={{
        class: 'success-page'
    }} />
        <SuccessPageTemplate
          title={post.frontmatter.title}
          content={post.html}
        />
        <Prefooter />
      </Layout>
    )
}

export default SuccessPage

//I think this actually makes sense now. Query name needs to match React component. I think. Not certain
export const successPageQuery = graphql`
query SuccessPage($id: String!) {
  markdownRemark(id: { eq: $id }) {
    html
    frontmatter {
      title
    }
  }
}
`